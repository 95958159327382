<template>
    <li class="bets">
        <table>
            <thead>
                <tr class="bets-header">
                    <th class="bets-date">{{date}}</th>
                    <th class="bets-title" colspan="2">{{market.event}}</th>
                    <th class="bets-profit" colspan="2">
                        <span class="bets-profit-prec">{{ profit.percent.toFixed(2)}}</span>%
                        <span>   </span>
                        <span class="bets-profit-summ">{{ profit.result.toFixed(2) }}</span>p.
                    </th>
                    <th class="bets-remove"><button class="bets-save-btn" title="Сохранить" @click="updateBet(market)">💾</button><button @click="deleteBet(market)" class="bets-remove-btn unselectable" title="Удалить">❌</button></th>
                </tr>
                <tr class="bets-collnames">
                    <td>Контора</td>
                    <td>Ставка</td>
                    <td>Кф.</td>
                    <td>Сумма ставки</td>
                    <td>Зашла?</td>
                    <td>Профит</td>
                </tr>
            </thead>
            <tbody>
                <tr class="bet" v-for="(bet, i) in market.markets" :key="i">
                    <td class="bet-bk"><input type="text" v-model="bet.name"></td>
                    <td class="bet-market"><input type="text" v-model="bet.market"></td>
                    <td class="bet-price"><input type="number" min="0" v-model.number="bet.price"></td>
                    <td class="bet-summ"><input type="number" min="0" v-model.number="bet.bet"></td>
                    <td class="bet-result"><input type="checkbox" v-model="bet.success"></td>
                    <td class="bet-profit">{{profit[i].toFixed(2)}}</td>
                </tr>
                <!-- <tr class="bet">
                    <td class="bet-bk"><input type="text" v-model="market.markets[2].name"></td>
                    <td class="bet-market"><input type="text" v-model="market.markets[2].market"></td>
                    <td class="bet-price"><input type="text" v-model="market.markets[2].price"></td>
                    <td class="bet-summ"><input type="text" v-model="market.markets[2].bet"></td>
                    <td class="bet-result"><input type="checkbox" v-model="market.markets[2].success"></td>
                    <td class="bet-profit">{{profit[2].toFixed(2)}}</td>
                </tr> -->
            </tbody>
        </table>
    </li>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    props: ["market"],
    computed: {
        date(){
            const date = new Date(this.market.date);
            return pad(date.getDate())+
            "." + pad(date.getMonth() + 1) +
            "." + `${date.getFullYear()}`.slice(-2)+
            " " + pad(date.getHours()) +
            ':' + pad(date.getMinutes());
            function pad(number){
                if (number < 10) return '0' + number;
                return number;
            }
        },
        profit(){

            let { success:success_1, bet:bet_1, price:price_1 }  = this.market.markets[1];
            let { success:success_2, bet:bet_2, price:price_2 }  = this.market.markets[2];


            price_1 = price_1 ? parseFloat(price_1): 0
            price_2 = price_2 ? parseFloat(price_2): 0

            bet_1 = bet_1 ? parseFloat(bet_1): 0
            bet_2 = bet_2 ? parseFloat(bet_2): 0

            const profit_1 = success_1 && success_2 ? (price_1*bet_1)-bet_1 : !success_1 && !success_2 ? -bet_1 : (price_1*bet_1)-(bet_1+bet_2)
            const profit_2 = success_2 && success_1 ? (price_2*bet_2)-bet_2 : !success_2 && !success_1 ? -bet_2 : (price_2*bet_2)-(bet_2+bet_1)

            const summ = success_1 == success_2 ? profit_1+profit_2 : success_1 ? profit_1 : profit_2;
            const perc = summ / ((bet_1+bet_2)/100)

            return {1:profit_1, 2:profit_2, result: summ, percent: perc}
        }
    },
    methods:{
        ...mapActions(["updateBet", "deleteBet"]),
    }
}
</script>