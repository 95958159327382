<template>
    <Loader v-if="loading"/>
    <div class="content" v-else>
		<div class="info-panel">
			Не забывайте сохранять измениения в статистике, нажав на специальную кнопку "💾"
		</div>
        <div id="bets-list" class="no-bets" v-if="!bets.length">Нет ни одной сохраненной ставки за выбранный период</div>
        <transition-group name="fade" tag="ul" id="bets-list" v-scroll="load_more" v-else>
            <Bet v-for="market in loaded_bets" :key="market._id" :market="market" />
        </transition-group>
        <div class="bets-info">
            <div class="bets-info-head">Статистика</div>
            <div class="filter">
                <input type="date" v-model="date_end">
                <br>
                <input type="date" v-model="date_start">
                <br>
                <button type="submit" class="button" @click="load_bets">Показать</button>
            </div>
            <table class="bets-stat">
                <tbody>
                    <tr>
                        <td>Профит:</td>
                        <td><strong>{{stats.profit.toFixed(2)}} p.</strong></td>
                    </tr>
                    <tr>
                        <td>Средний %:</td>
                        <td><strong>{{stats.percent.toFixed(2)}} %</strong></td>
                    </tr>
                </tbody>
            </table>
            <!-- <ul>
                <li>Сегодня: <span>{{stats.today.toFixed(2)}}</span>p.</li>
                <li>Вчера: <span>{{stats.yesterday.toFixed(2)}}</span>p.</li>
                <li>Неделя: <span>{{stats.week.toFixed(2)}}</span>p.</li>
                <li>Месяц: <span>{{stats.mouth.toFixed(2)}}</span>p.</li>
            </ul> -->
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Bet from "../components/Bet.vue"
import Loader from "../components/Loader.vue";

export default {
    data(){
        return {
            loading: true,
            load_bets_c: 20,
            date_start: date_format(Date.now()),
            date_end: date_format(Date.now() - 7*24*60*60*1000)
        }
    },
    components: {
        Bet,
        Loader
    },
    computed: {
        ...mapGetters(['isLoggedIn', "bets", "stats"]),
        loaded_bets(){
            console.log("update", this.bets.length, this.load_bets_c)
            return this.bets.slice(0, this.load_bets_c);
        }
    },
    methods: {
        ...mapActions(["loadBets"]),
        load_more(){
            if (this.$route.path !== "/bets" || this.bets.length < this.load_bets_c) return;
            if (document.documentElement.scrollHeight - window.innerHeight - window.scrollY < 800){
                this.load_bets_c += 20;
            }
        },
        async load_bets(){
            this.loading = true;
            console.log(this.date_start >= this.date_end)
            const start = this.date_start >= this.date_end ? this.date_start : this.date_end;
            const end = this.date_start >= this.date_end ? this.date_end : this.date_start;
            await this.loadBets({start, end})
            this.loading = false;
        }
    }, 
    watch: {
        isLoggedIn(){
            if (!this.isLoggedIn) this.$router.push("/")
        }
    },
    async mounted(){
        await this.loadBets({start: this.date_start})
        this.loading = false;
    },
    metaInfo(){
        return {
            title: "Ваши ставки. Mint"
        }
    }
}

function date_format(_date){
    const date = new Date(_date);
    return `${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDate())}`

    function addZero(num){
        if (num < 10) return '0'+num;
        else return num;
    }
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.5s;
    }
    .fade-enter, .fade-leave-to /* .list-leave-active до версии 2.1.8 */ {
        opacity: 0;
        transform: translateX(-30px);
    }
</style>